import React from 'react'
import Slider from "react-slick";
import './News.css'
import Nazimuysal from '../Assets/nazimuysal.jpg'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';

const News = () => {
    const {t,i18n} = useTranslation()
    const settings = {
        className: "center",
        infinite: true,
        centerPadding: "20px",
        arrows: true,
        slidesToScroll:1,
        slidesToShow: 3,
        speed: 500,
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <div className='news'>
        <div className="container">
            <div>
                <h2 className='bslk'>{t("pages.news")}</h2>
                
            </div>
            <div style={{margin:'auto'}}>
                <div style={{marginTop:'20px'}}>
                    <Slider {...settings}>
                    {data.map((d)=>(
                        <div className='news-card'>
                            <div className='card-img'>
                                <img src={d.img} alt="" />
                            </div>
                            <div className='card-icerik'>
                               <p className='kategori'>{d.kategori}</p>
                               <p>{d.yazi}</p>
                            </div>
                        </div>
                    ))}
                    </Slider>
                </div>
            </div>
        </div>
    </div>
  )
}

const data = [
    {
        kategori:`Haber`,
        img:`${Nazimuysal}`,
        yazi:`2N Mekatronik'ten Türkiye'de İlk %100 Yerli Baca Filtre Sistemi`
    },
    {
        kategori:`Haber`,
        img:`${Nazimuysal}`,
        yazi:`2N Mekatronik'ten Türkiye'de İlk %100 Yerli Baca Filtre Sistemi`
    },
    {
        kategori:`Haber`,
        img:`${Nazimuysal}`,
        yazi:`2N Mekatronik'ten Türkiye'de İlk %100 Yerli Baca Filtre Sistemi`
    },
]

export default News