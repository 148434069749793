import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import "./Home.css";
import gsap from "gsap";
import { ScrollSmoother } from "gsap-trial/ScrollSmoother";
import { useGSAP } from "@gsap/react";
import Filter from "../Filter/Filter";
import Service from "../Service/Service";
import Info from "../Info/Info";
import Reference from "../Reference/Reference";
import SocialMedia from "../SocialMedia/SocialMedia";
import News from "../News/News";
import PopUp from "../PopUp/PopUp";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/parallax";
import "swiper/css/autoplay";

import {
  Navigation,
  Pagination,
  Scrollbar,
  EffectFade,
  Parallax,
  Autoplay,
} from "swiper/modules";

import Hero1 from "../Assets/home-1.png";
import Hero2 from "../Assets/111.png";
import Hero3 from "../Assets/home11.png";
import PreLoader from "../PreLoader/PreLoader";
import { useTranslation } from "react-i18next";

// gsap.registerPlugin( ScrollSmoother);

const Home = () => {
  const { t, i18n } = useTranslation();

  // const location = useLocation();
  useGSAP(() => {
    const tl = gsap.timeline({ delay: 1 });

    tl.to(".background-wrapper", {
      scale: 1,
      duration: 1,
    });
    tl.to(".syf-bslk", {
      opacity: 1,
      duration: 1,
    });
    tl.to(".bslk-txt", {
      opacity: 1,
      ease: "power3.out",
    });
  });

  // useGSAP(()=>{
  //   ScrollSmoother.create({
  //     smooth: 1,
  //     effects: true,
  //     smoothTouch: 0.1,
  //   });
  // },{
  //   dependencies: [location],
  // })

  return (
    <>
      <PreLoader />
      {/* <div id="smooth-wrapper">
    <div id="smooth-content"> */}
      {/* <section className='hero-field pl2 pr2 mx-auto'>
      <div className='inner'>
          <div className="background-wrapper">
            <div className='blur-overlay'>
              <div className="icerik">
                <h1 className='syf-bslk'>GELECEĞİNİZİ KORUYORUZ</h1>
                <p className='bslk-txt'>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                    Voluptatem accusamus esse, aut eveniet ipsum ipsam doloremque
                    odio et minima a porro vitae vero voluptatibus? </p>

              </div>
              <a href="#sagliklicevre">
                <div className="scrolldown icon">
                  
                  <i class="fa-solid fa-chevron-down"></i>
                </div>
              </a>
             
            </div>
          </div>
             
      </div>

    </section> */}

      <section className="home">
        <Swiper
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            EffectFade,
            Parallax,
            Autoplay,
          ]}
          direction="horizontal"
          speed={900}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
            reverseDirection: true,
          }}
          slidesPerView={1}
          initialSlide={1}
          parallax={true}
          allowSlideNext={false}
          loop={true}
          effect={"fade"}
        >
          <div className="home__swiper">
            <div>
              <SwiperSlide>
                <article className="home__article parallax-bg">
                  <div className="home__data container">
                    <div className="home_content">
                      <h3
                        className="home_subtitle bslk-txt"
                        data-swiper-parallax="500"
                      >
                        {t("rambaca")}
                      </h3>
                      <h1
                        className="home_title syf-bslk"
                        data-swiper-parallax="400"
                      >
                        {t("filterUnit")}
                      </h1>
                      <p
                        className="home_text bslk-txt"
                        data-swiper-parallax="-300"
                      >
                        {t("ecoFilterSystem")}
                      </p>
                    </div>
                  </div>

                  <img className="home__img" src={Hero2} alt="" />
                  <div className="home__shadow"></div>
                </article>
              </SwiperSlide>
              <SwiperSlide>
                <article className="home__article">
                  <div className="home__data container">
                    <div className="home_content">
                      <h3
                        className="home_subtitle bslk-txt"
                        data-swiper-parallax="500"
                      >
                        {t("yourFuture")}
                      </h3>
                      <h1
                        className="home_title syf-bslk"
                        data-swiper-parallax="400"
                      >
                        {t("weProtect")}
                      </h1>
                      <ul
                        className="home_text bslk-txt list"
                        data-swiper-parallax="-300"
                      >
                        <li>
                          <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                          {t("remoteMonitoringControl")}
                        </li>
                        <li>
                          <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                          {t("fullyAutomaticWashing")}
                        </li>
                        <li>
                          <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                          {t("zeroFireRisk")}
                        </li>
                        <li>
                          <i className="fa-solid fa-arrow-right svc-icon"></i>{" "}
                          {t("zeroProductionLoss")}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <img className="home__img" src={Hero3} alt="" />
                  <div className="home__shadow"></div>
                </article>
              </SwiperSlide>
              <SwiperSlide>
                <article className="home__article">
                  <div className="home__data container">
                    <div className="home_content ">
                      <h3
                        className="home_subtitle bslk-txt"
                        data-swiper-parallax="500"
                      >
                        <span style={{ color: "rgba(15, 117, 188, 1)" }}>
                          2N
                        </span>{" "}
                        {t("withFilterSystem")}
                      </h3>
                      <h1
                        className="home_title syf-bslk"
                        data-swiper-parallax="400"
                      >
                        {t("cleanEnvironment1")}
                      </h1>
                      <p
                        className="home_text bslk-txt"
                        data-swiper-parallax="-300"
                      >
                        " {t("healthyEnvironmentHealthyHuman")} "
                      </p>
                    </div>
                  </div>

                  <img className="home__img" src={Hero1} alt="" />
                  <div className="home__shadow"></div>
                </article>
              </SwiperSlide>

              {/* pagination */}
              <div className="swiper-pagination"></div>
              {/* navigation-buttons */}
              <div className="swiper-navigation">
                <div className="swiper-button-prev">
                  <i class="fa-solid fa-angle-left"></i>
                </div>
                <div className="swiper-button-next">
                  <i class="fa-solid fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </Swiper>
      </section>

      <Filter />
      <Info />
      <Service />
      <Reference />
      <News />
      <SocialMedia />
      {/* <PopUp /> */}
      {/* </div>
    </div> */}
    </>
  );
};

export default Home;
