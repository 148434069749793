import React, { useState, useEffect, useRef } from "react";
import "./ContactP.css";
import BreadCrumps from "../Breadcrumps/Breadcrumps";
import Map from "./Map";
import Logo from "../Assets/2n-logo.png";

import emailjs from "@emailjs/browser";

import { useJsApiLoader } from "@react-google-maps/api";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { useTranslation } from "react-i18next";

const ContactP = () => {
  const { t, i18n } = useTranslation();
  // email js api
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [konu, setKonu] = useState("");
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (notification.message) {
      const timeout = setTimeout(() => {
        setNotification({ message: "", type: "" });
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [notification]);

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const serviceId = "service_v45le6x";
    const templateId = "template_k6l97uc";
    const publicKey = "7qmwI-FhI3hgSGtfL";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "2N Mekatronik",
      message: message,
      number: number,
      konu: konu,
    };

    //send email usein emailJs
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("E-mail başarıyla gönderildi !", response);
        setNotification({
          message: "E-posta başarıyla gönderildi.",
          type: "success",
        });
        setName("");
        setEmail("");
        setMessage("");
        setNumber("");
        setKonu("");
      })
      .catch((error) => {
        console.error("Mesaj Gönderilemedi:", error);
        setNotification({
          message: "E-posta gönderilirken bir hata oluştu.",
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false); // Gönderim tamamlandı   ğında loading durumunu devre dışı bırak
      });
  };

  // email js api

  // sayfa açılış animasyonu
  const [activeIndex, setActiveIndex] = useState(1); // Başlangıçta "Bize Ulaşın" aktif

  const handleClick = (index) => {
    if (index !== activeIndex) {
      setActiveIndex(index);
    }
  };
  // sayfa açılış animasyonu
  // google maps api
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBjPd4e9ULXxrNK5RfAVNxxUqbOOIqfsRA",
  });
  // google maps api

  return (
    <HelmetProvider>
      <Helmet>
        <title>İletişim | 2N Mekatronik®</title>
        <meta
          name="description"
          content="Bize Ulaşın. 2N Mekatronik; Zafer Mah. Yeni Sanayi Sitesi İmalat 8. Sk. No:14 Çorlu/Tekirdağ; 0282 673 27 29 · info@2nmekatronik.com. © 2021 2N Mekatronik Tüm ..."
        ></meta>
        <meta
          property="og:description"
          content="Bize Ulaşın. 2N Mekatronik; Zafer Mah. Yeni Sanayi Sitesi İmalat 8. Sk. No:14 Çorlu/Tekirdağ; 0282 673 27 29 · info@2nmekatronik.com. © 2021 2N Mekatronik Tüm ..."
        ></meta>
        <meta
          property="og:url"
          content="https://www.2nmekatronik.com.tr/hizmetler/"
        ></meta>
      </Helmet>
      <>
        <BreadCrumps baslik="Geleceğinizi Koruyoruz !" page="İletişim" />
        <div className="contact">
          <div className="">
            <div className="g2">
              <div className="maps w13 item1 ">
                <div className="harit">
                  <a href="https://maps.app.goo.gl/9GxpF6grdRim4meL7">
                    <div className="map-marker">
                      <div>
                        <img src={Logo} alt="" width={135} />
                        <div className="sep-horizontal"></div>
                        <p className="txt">
                          Yol Tarifi Al{" "}
                          <i class="fa-solid fa-location-crosshairs"></i>
                        </p>
                      </div>
                    </div>
                  </a>
                  <Map isLoaded={isLoaded} />
                </div>
              </div>
              <div className="item2" style={{ position: "relative" }}>
                <div className="contact-button">
                  <div
                    className={`txt3 ${activeIndex === 0 ? "active" : ""}`}
                    onClick={() => handleClick(0)}
                  >
                    {t("contact.contactForm")}
                  </div>
                  <div
                    className={`txt3 ${activeIndex === 1 ? "active" : ""}`}
                    onClick={() => handleClick(1)}
                  >
                    {t("contact.contactInfo")}
                  </div>
                </div>

                <div
                  className={`contact-text ${
                    activeIndex === 1 ? "active" : ""
                  }`}
                >
                  <div>
                    <h2 className="bslk2">{t("contact.title")}</h2>
                  </div>
                  <br />
                  <div className="sep"></div>
                  <br />
                  <div className="contact-icerik">
                    <p className="bslk-txt">
                      2N Mekatronik Filtre Sistemleri ve Makina Sanayi Ticaret
                      LTD.
                    </p>
                    <br />
                    <p className="txt">
                      Marmaracık, 1982 Sk No:13/1, 59850 Ergene/Tekirdağ
                    </p>
                    <br />
                    <ul>
                      <li className="txt">
                        <a href="mailto:info@2nmekatronik.com">
                          <i className="fa-solid fa-location-dot"></i>
                          <span className="pl1">info@2nmekatronik.com</span>
                        </a>
                      </li>
                      <li className="txt">
                        <a href="tel:02826732729">
                          <i className="fa-solid fa-phone-volume"></i>
                          <span className="pl1">0282 673 27 29</span>
                        </a>
                      </li>
                      <li className="txt">
                        <a href="tel:05327852959">
                          <i className="fa-solid fa-mobile"></i>
                          <span className="pl1">+90 532 785 29 59</span>
                        </a>
                      </li>
                      <li className="txt">
                        <a href="tel:05336438621">
                          <i className="fa-solid fa-mobile"></i>
                          <span className="pl1">+90 533 643 86 21</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className={`contact-text ${
                    activeIndex === 0 ? "active" : ""
                  }`}
                >
                  <div>
                    <h2 className="bslk2">{t("contact.contactForm")}</h2>
                  </div>
                  <br />
                  <div className="sep"></div>
                  <br />
                  <div className="contact-icerik">
                    <div class="formbold-main-wrapper w50 w100 ">
                      <div class="formbold-form-wrapper">
                        <form ref={form} onSubmit={handleSubmit}>
                          <div class="formbold-mb-5">
                            <label for="name" class="formbold-form-label">
                              {" "}
                            </label>
                            <div className="entryarea">
                              <input
                                required
                                value={name}
                                type="text"
                                name="name"
                                id="name"
                                class="formbold-form-input"
                                onChange={(e) => setName(e.target.value)}
                              />
                              <div className="labelline">
                                {t("contact.name")}, {t("contact.surname")}
                              </div>
                            </div>
                          </div>

                          <div class="formbold-mb-5">
                            <label
                              for="email"
                              class="formbold-form-label"
                            ></label>
                            <div className="entryarea">
                              <input
                                required
                                value={email}
                                type="email"
                                name="email"
                                id="email"
                                class="formbold-form-input"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              <div className="labelline">
                                {t("contact.email")}
                              </div>
                            </div>
                          </div>

                          <div class="formbold-mb-5">
                            <label
                              for="firma"
                              class="formbold-form-label"
                            ></label>
                            <div className="entryarea">
                              <input
                                required
                                value={konu}
                                type="tel"
                                name="number"
                                id="number"
                                class="formbold-form-input"
                                onChange={(e) => setKonu(e.target.value)}
                              />
                              <div className="labelline">
                                {t("contact.phone")}
                              </div>
                            </div>
                          </div>

                          <div class="formbold-mb-5">
                            <label for="message" class="formbold-form-label">
                              {" "}
                            </label>
                            <div className="entryarea">
                              <textarea
                                required
                                value={message}
                                rows="4"
                                name="message"
                                id="message"
                                class="formbold-form-input"
                                style={{ backgroundColor: "transparent" }}
                                onChange={(e) => setMessage(e.target.value)}
                              ></textarea>
                              <div className="labelline">
                                {t("contact.message")}
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <button type="submit" className="btn">
                              {loading && (
                                <div class="dot-spinner">
                                  <div class="dot-spinner__dot"></div>
                                  <div class="dot-spinner__dot"></div>
                                  <div class="dot-spinner__dot"></div>
                                  <div class="dot-spinner__dot"></div>
                                  <div class="dot-spinner__dot"></div>
                                  <div class="dot-spinner__dot"></div>
                                  <div class="dot-spinner__dot"></div>
                                  <div class="dot-spinner__dot"></div>
                                </div>
                              )}
                              {!loading && notification.message && (
                                <span style={{ color: "white" }}>
                                  {notification.message}
                                </span>
                              )}
                              {!loading && !notification.message && (
                                <span style={{ color: "white" }}>
                                  {t("contact.send")}{" "}
                                  <i class="fa-solid fa-arrow-right"></i>
                                </span>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default ContactP;
