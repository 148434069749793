import React from 'react'
import '../News/News.css'
import Slider from "react-slick";
import './News.css'
import Nazimuysal from '../Assets/nazimuysal.jpg'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Breadcrumps from '../Breadcrumps/Breadcrumps'
import BirinciHaber from './HaberSayfalari/BirinciHaber';
import { Link } from 'react-router-dom';
import { Helmet,HelmetProvider  } from 'react-helmet-async'

import { useTranslation } from 'react-i18next';

const NewsP = () => {
  const {t,i18n} = useTranslation()
    const clickHandle = lang =>{
      i18n.changeLanguage(lang)
    }

    const settings = {
        className: "center",
        infinite: true,
        centerPadding: "20px",
        arrows: true,
        slidesToScroll:1,
        slidesToShow: 3,
        speed: 500,
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
  return (
    <HelmetProvider>
    <>
      <Helmet>
        <title>Haberler | 2N Mekatronik®</title>
        <meta name="description" content="Kaliteli hizmetler sunarken çevreye zarar vermemek, geri dönüşüm sağlayan hizmetler sunmak, atık sıcak havanızın geri dönüşüm ile işletmenize enerji ..."></meta>
        <meta property="og:description" content="Kaliteli hizmetler sunarken çevreye zarar vermemek, geri dönüşüm sağlayan hizmetler sunmak, atık sıcak havanızın geri dönüşüm ile işletmenize enerji ..."></meta>
        <meta property="og:url" content="https://www.2nmekatronik.com.tr/haberler/"></meta>
      </Helmet>
      <Breadcrumps baslik={t("yourFutureWeProtect")} page={t("pages.news")}/>
       <div className='news-p'>
        <div className="container">
            <div style={{margin:'auto'}}>
                <div style={{marginTop:'20px'}}>
                    <Slider {...settings}>
                    {data.map((d,index)=>(
                            <Link key={index} to={data.haber}>
                        <div className='news-card'>
                                <div className='card-img'>
                                    <img src={d.img} alt="" />
                                </div>
                                <div className='card-icerik'>
                                <p className='kategori'>{d.kategori}</p>
                                <p>{d.yazi}</p>
                                </div>
                        </div>
                            </Link>
                    ))}
                    </Slider>
                </div>
            </div>
        </div>
    </div>
    </>
    </HelmetProvider>
  )
}
const data = [
    {
        kategori:`Haber`,
        img:`${Nazimuysal}`,
        yazi:`2N Mekatronik'ten Türkiye'de İlk %100 Yerli Baca Filtre Sistemi`,
        haber:`${BirinciHaber}`,
    },
    {
        kategori:`Haber`,
        img:`${Nazimuysal}`,
        yazi:`2N Mekatronik'ten Türkiye'de İlk %100 Yerli Baca Filtre Sistemi`
    },
    {
        kategori:`Haber`,
        img:`${Nazimuysal}`,
        yazi:`2N Mekatronik'ten Türkiye'de İlk %100 Yerli Baca Filtre Sistemi`
    },
]


export default NewsP