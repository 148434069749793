import React from 'react'
import { useTranslation } from 'react-i18next';
import Breadcrumps from '../Breadcrumps/Breadcrumps';

const Kvkk = () => {
    const {t,i18n} = useTranslation()
  return (
    <>
    <Breadcrumps baslik={t("yourFutureWeProtect")} page="KVKK Aydınlatma Metni"/>
    
    <div className='kvkk'>
        <div className="container">
            <h2 className='mb4'><span>KVKK</span> Aydınlatma Metni</h2>
            <div>
                <h3 className='bslk-txt mb2'><span>Kişisel veri nedir, kim işler?</span></h3>
                <p className='txt mb4'>6698 sayılı Kişisel Verilerin Korunması Kanununun 3. maddesi uyarınca kişisel veri, kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi ifade eder. 2N Mekatronik, 6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca “veri sorumlusu” sıfatıyla kişisel verilerinizi kanuna uygun olarak kaydedecek, saklayacak, güncelleyecek, işleyecek ve üçüncü kişilere açıklayabilecek ve aktarabilecektir.</p>
                <h3 className='bslk-txt mb2'><span> Kişisel verileriniz hangi amaç ile işlenebilir?</span></h3>
                <p className='txt mb4'>Toplanan kişisel verileriniz KVK Kanunu’nun ‐İlgilinin açık rızası (m.5/1) <br />
                ‐Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması(m.5/2.ç) <br />
                Kişisel veri işleme şartları dahilinde şirketimizde kullanılan bilgisayar vb. cihazlardan sistem üzerinden log kayıtlarının tutulması ile elde edilen verilerin toplanığı ve verilere yasal taleplere cevap verebilmek, işyeri kurallarına uyumu denetlemek ve teknik destek vermek amacıyla işlenebilecektir.</p>
                <h3 className='bslk-txt mb2'><span>İşlenen kişisel verileriniz kimlere ve hangi amaçla aktarılabilir?</span></h3>
                <p className='txt mb4'>Toplanan kişisel verileriniz; Toplanan kişisel verileriniz, kullanılan bilgisayar vb. cihazlardan sistem üzerinden log kayıtlarının tutulması ile elde edilen verilerin toplanığı ve verilere yasal taleplere cevap verebilmek, işyeri kurallarına uyumu denetlemek ve teknik destek vermek amacıyla; iştirak Şirketleriyle, iş ortaklarımızla, tedarikçilerimizle, Şirket yetkilileriyle, kanunen yetkili kamu kurumları ve özel kişilerle, KVK Kanunu’nun 8. ve 9. Maddeleri uyarınca aktarılabilecektir.</p>
                <h3 className='bslk-txt mb2'><span>Kişisel verilerinizi hangi hukuki sebeple ve hangi yöntemle topluyoruz?</span></h3>
                <p className='txt mb4'>Kişisel verileriniz Şirketimiz tarafından bu Aydınlatma 2. maddesinde belirtilen hukuki sebeplerle, internet sitesi üzerinde bulunan formlar, telefon, e‐posta, yazışma ve benzeri ortamlarda sözlü veya yazılı olarak toplanmaktadır. Toplanan kişisel verileriniz KVK Kanunu’na uygun olarak ve bu Aydınlatma Metni’nin 2. ve 3. maddelerinde belirtilen amaçlarla da işlenebilmekte ve aktarılabilmektedir.</p>
                <h3 className='bslk-txt mb2'><span>6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca haklarınız nelerdir?</span></h3>
                <p className='txt mb4'>6698 sayılı Kanun’un 11. Maddesi uyarınca, kişisel verilerinizin silinip silinmediğini öğrenme, işlenmiş ise buna ilişkin bilgi talep etme, işleme amacını öğrenme ve bu amaca uygun kullanılıp kullanılmadığını öğrenme, kişisel verilerinizin aktarıldığı kişileri bilme, kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde düzeltilmesini, Kanun’da düzenlenen hallerde kişisel verilerinizin silinmesini veya yok edilmesini ve bu iki durumun verilerinizin paylaşıldığı kişilere bildirilmesini isteme, verilerinizin münhasıran otomatik bir sistem vasıtası ile işlenmesi suretiyle aleyhinize bir sonuç çıkması halinde itiraz etme ve kanuna aykırı olarak verilerinizin işlenmesi halinde zararlarınızın giderilmesini isteme hakkına sahipsiniz.</p>
                <h3 className='bslk-txt mb2'><span>Veri sorumlusuna nasıl başvuracaksınız?</span></h3>
                <p className='txt mb4'>Yukarıda sayılan haklarınızı, yazılı olarak ıslak imzalı şekilde veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da ilgili kişi tarafından Şirketimize daha önce bildirilen ve Şirketimiz sisteminde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle Şirketimize iletebilirsiniz. Yazılı olarak yapılacak başvurular için adres Zafer Mah. Yeni Sanayi Sitesi İmalat 8. Sk. No:14 Çorlu/Tekirdağ adresidir. Başvurunuzu internet sitemizde yer alan Başvuru Formu ile gerçekleştirebilirsiniz. Kişisel veri sahibinin kendisi dışında bir kişinin talepte bulunması için konuya ilişkin olarak kişisel veri sahibi tarafından başvuruda bulunacak kişi adına düzenlenmiş özel vekâletname bulunmalıdır. Şirketimize iletilen usulüne uygun talepler en geç otuz gün içinde ücretsiz olarak sonuçlandırılacaktır. Cevabın on sayfadan fazla olması halinde, on sayfanın üzerindeki her sayfa için 1 Türk Lirası işlem ücreti alınabilir. Başvuruya cevabın CD, flash bellek gibi bir kayıt ortamında verilmesi halinde ise veri kayıt ortamının maliyeti talep edilebilir. Şirket, başvuruda bulunan kişinin kişisel veri sahibi olup olmadığını tespit etmek ve talepleri değerlendirmek için gerekli görmesi halinde ek bilgi talep edebilir ve başvuruda belirtilen hususları netleştirmek adına, kişisel veri sahibine başvurusu ile ilgili soru yöneltebilir.</p>
                <h3>Adı ve Soyadı<br /><br />İmzası<br /><br />Tarih</h3>
            </div>
        </div>
    </div>
    </>
  )
}

export default Kvkk