import React from 'react'
import './Reference.css'
import Mertul from '../Assets/referans/mertul.png'
import Altinyildiz from '../Assets/referans/altinyildiz.png'
import senova from '../Assets/referans/senova.png'
import rayon from '../Assets/referans/rayon.png'
import yorukler from '../Assets/referans/yorukler.png'
import akinlar from '../Assets/referans/akinlar.png'
import assos from '../Assets/referans/assos.png'
import bakay from '../Assets/referans/bakay.png'
import elses from '../Assets/referans/else.png'
import ipektul from '../Assets/referans/ipektul.png'
import ismen from '../Assets/referans/ismen.png'
import mavis from '../Assets/referans/mavis.png'

import { useTranslation } from 'react-i18next';
const Reference = () => {
    const {t,i18n} = useTranslation()
  return (
    <div className='Reference mt4'>
        <div className="container">
            <div>
                <h2 className='bslk-txt pb5'>{t("referenceHead.b1")} <br /> {t("referenceHead.b2")}</h2>
                <div className='g4 cards'>
                    <div>
                        <img src={Mertul} width={200} alt="" />
                    </div>
                    <div>
                        <img src={Altinyildiz} width={200} alt="" />
                    </div>
                    <div>
                        <img src={senova} width={200} alt="" />
                    </div>
                    <div>
                        <img src={rayon} width={200} alt="" />
                    </div>
                </div>
                <div className='g4 cards mt2'>
                    <div>
                        <img src={yorukler} width={200} alt="" />
                    </div>
                    <div>
                        <img src={akinlar} width={200} alt="" />
                    </div>
                    <div>
                        <img src={assos} width={200} alt="" />
                    </div>
                    <div>
                        <img src={bakay} width={200} alt="" />
                    </div>
                </div>
                <div className='g4 cards mt2'>
                    <div>
                        <img src={elses} width={200} alt="" />
                    </div>
                    <div>
                        <img src={ipektul} width={200} alt="" />
                    </div>
                    <div>
                        <img src={ismen} width={200} alt="" />
                    </div>
                    <div>
                        <img src={mavis} width={200} alt="" />
                    </div>
                </div>
                <div className='bb'>
                      <a className='btn' style={{fontWeight:600,letterSpacing:'2px'}} href="/referanslarimiz">
                        <div>
                        <p>{t("references")}</p>
                        <i class="fa-solid fa-arrow-right"></i>

                        </div>
                      </a>
                
                    </div>
            </div>
               
        </div>
    </div>
  )
}

export default Reference