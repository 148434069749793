
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";



// Pages
import Home from "./components/Home/Home";
import Footer from "./components/Layouts/Footer";
import Navbar from "./components/Layouts/Navbar";
import ContactP from "./components/Contact/ContactP";
import KurumsalP from "./components/Kurumsal/KurumsalP";
import RamBacaP from "./components/Rambaca/RamBacaP";
import Kazanimisi from "./components/Kazanimisi/Kazanimisi";
import NewsP from "./components/NewsP/NewsP";
import ReferenceP from "./components/ReferenceP/ReferenceP";
import HizmetlerP from "./components/HizmetlerP/HizmetlerP";
import i18n from "./i18n";
import Certificatep from "./components/CertificateP/Certificatep.jsx";
import Kvkk from "./components/Kvkk/Kvkk.jsx";
// import WpButton from "./components/WpButton/WpButton";
// import TelButton from "./components/WpButton/TelButton";
// import Services from "./components/Services/Services";
// import P404 from "./components/P404/P404";

const router = createBrowserRouter([
  {
    path:'/',
    element:<Navbar />,
    children:[
    {index:true,element: (<Home />),},
    {path: "/anasayfa",element: (<Home />),},
    {path: "/kurumsal",element: (<KurumsalP />),},
    {path: "/iletisim",element: (<ContactP />),},
    {path: "/ram-filtresi",element: (<RamBacaP />),},
    {path: "/isi-geri-kazanimi",element: (<Kazanimisi/>),},
    {path: "/haberler",element: (<NewsP/>),},
    {path: "/referanslarimiz",element: (<ReferenceP/>),},
    {path: "/hizmetlerimiz",element: (<HizmetlerP/>),},
    {path: "/sertifikalar",element: (<Certificatep/>),},
    {path: "/kvkk-aydinlatma-metni",element: (<Kvkk/>),},
  
    // {path: "/404",element: (<P404 />),},
    

    // {path: "/foto-galeri",element: (<Images />),},
    // {path: "/video-galeri",element: (<VideoGallery />),},
    
  ]
  }
])

function App() {
  return (
    <div>
      <div>
        <RouterProvider router={router} />
        <Footer/>
     
    </div>
    </div>
  );
}

export default App;
