import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

// const resources = {
//     en: {
//       translation: {
//         "welcome": "Welcome to React and react-i18next"
//       }
//     },
//     tr: {
//       translation: {
//         "welcome": "merhaba react i18n paketi kullanıyyorum"
//       }
//     }
//   };

  i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
        lng:'tr',
        // resources
    })

    export default i18n