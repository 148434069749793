import React from "react";
import "./Footer.css";

import Logo from "../Assets/2n-logo.png";
import Footerbaca from "../Assets/footer-baca.jpg";
import IsoComp from "../Assets/iso-company.png";
import IsoOrg from "../Assets/iso-org.png";
import IsoOrg2 from "../Assets/iso-org2.png";
import Ce from "../Assets/ce.png";
import YerliUretim from "../Assets/yerli-uretim.png";
import Patent from "../Assets/patent.png";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer>
      <div className="foot mt4">
        <img className="footer-baca" src={Footerbaca} alt="" />
        {/* <img className='footer-banner' src="./img/2n-favicon.png" alt="" /> */}
        <div className="container">
          <div className="sec aboutus">
            <a
              href=""
              className="footer-logo"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={Logo}
                className=""
                alt="2n mekatronik logo"
                width="50%"
                height="200px"
                role="logo"
              />
            </a>
            <p className="foot-text">
              2N Mekatronik Filtre Sistemleri ve Makina Sanayi Ticaret LTD.
            </p>
            <div className="middle">
              <div className="social-btn">
                <img src={IsoComp} alt="" />
              </div>
              <div className="social-btn">
                <img src={IsoOrg} alt="" />
              </div>

              <div className="social-btn">
                <img src={IsoOrg2} alt="" />
              </div>
            </div>
            <div className="middle">
              <div className="social-btn">
                <img src={Ce} alt="" />
              </div>
              <div className="social-btn">
                <img src={YerliUretim} alt="" />
              </div>
              <div className="social-btn">
                <img src={Patent} alt="" />
              </div>
            </div>
          </div>
          <div className="sec quicklinks">
            <h2>{t("layout.pages")}</h2>
            <ul>
              <li>
                <a href="/">{t("layout.homepage")}</a>
              </li>
              <li>
                <a href="/kurumsal">{t("layout.corporate")}</a>
              </li>
              <li>
                <a href="/hizmetlerimiz">{t("layout.services")}</a>
              </li>
              <li>
                <a href="/referanslarimiz">{t("layout.references")}</a>
              </li>
              <li>
                <a href="/haberler">{t("layout.news")}</a>
              </li>
              <li>
                <a href="/iletisim">{t("layout.contact")}</a>
              </li>
            </ul>
          </div>
          <div className="sec quicklinks">
            <h2>{t("layout.products")}</h2>
            <ul>
              <li>
                <a href="/ram-filtresi">{t("ramExhaustFilter.title")}</a>
              </li>
              <li>
                <a href="/isi-geri-kazanimi">{t("isiGeriKazanimi.title")}</a>
              </li>
            </ul>
          </div>
          <div className="sec contact">
            <h2>{t("layout.contact")}</h2>
            <ul className="info">
              <li>
                <span>
                  <ion-icon name="call-outline"></ion-icon>
                  <p>
                    <a href="tel:02826732729" data-replace="this link">
                      0282 673 27 29
                    </a>
                  </p>
                </span>
              </li>
              <li>
                <span>
                  <ion-icon name="mail-outline"></ion-icon>
                  <p>
                    <a href="mailto:info@2nmekatronik.com">
                      info@2nmekatronik.com
                    </a>
                  </p>
                </span>
              </li>
              <li>
                <span>
                  <ion-icon style={{ fontSize: "37px" }} name="pin"></ion-icon>
                  <p>
                    <a href="https://maps.app.goo.gl/9GxpF6grdRim4meL7">
                      Marmaracık, 1982 Sk No:13/1, 59850 Ergene/Tekirdağ
                    </a>
                  </p>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyrightText">
        <div className="container">
          <p>
            {" "}
            © 2024{" "}
            <a
              style={{ display: "inline-block" }}
              href="https://2nmekatronik.com.tr"
            >
              <span style={{ fontWeight: 600 }}>2nmekatronik.com</span>
            </a>{" "}
            | Tüm Hakları Saklıdır.
          </p>
          <div>
            <a
              style={{ fontWeight: "800", fontSize: "19px" }}
              href="http://www.plazayazilim.com"
            >
              Plaza Yazılım
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
