import React, { useLayoutEffect, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Breadcrumps from "../Breadcrumps/Breadcrumps";
import "./RamBacaP.css";
import Banner from "../Assets/G2.7.JPG";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Hero1 from "../Assets/hero1.jpg";
import Hero2 from "../Assets/hero2.jpg";
import Hero3 from "../Assets/hero3.jpg";
import Hero4 from "../Assets/hero4.jpg";

import LightGallery from "lightgallery/react/Lightgallery.es5";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-share.css";
import "lightgallery/css/lg-thumbnail.css";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgShare from "lightgallery/plugins/share";

import AtikHava from "../Assets/filtreparçalari/atik-hava.png";
import Ayristirma from "../Assets/filtreparçalari/ayristirma-unite.png";
import Elek from "../Assets/filtreparçalari/elek.png";
import Ekonomizer from "../Assets/filtreparçalari/ekonomizer.png";
import Damla from "../Assets/filtreparçalari/damla-tutucu.png";
import Yogusturma from "../Assets/filtreparçalari/yoguşturma.png";
import RadyalFan from "../Assets/filtreparçalari/radyal-fan.png";

import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);
const RamBacaP = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  useLayoutEffect(() => {
    const details = gsap.utils.toArray(".info-texts:not(:first-child)");
    const photos = gsap.utils.toArray(".desktopPhoto:not(:first-child)");

    gsap.set(photos, { yPercent: 101 });

    const allPhotos = gsap.utils.toArray(".desktopPhoto");

    // create
    let mm = gsap.matchMedia();

    // add a media query. When it matches, the associated function will run
    mm.add("(min-width: 820px)", () => {
      // this setup code only runs when viewport is at least 600px wide
      console.log("desktop");

      ScrollTrigger.create({
        trigger: ".baca-info",
        start: "top top",
        end: "bottom bottom",
        pin: ".info-image",

        scrub: 2,
      });

      //create scrolltrigger for each details section
      //trigger photo animation when headline of each details section
      //reaches 80% of window height
      details.forEach((detail, index) => {
        let headline = detail.querySelector(".info-text");
        let animation = gsap
          .timeline()
          .to(photos[index], { yPercent: 0 })
          .set(allPhotos[index], { autoAlpha: 0 });
        ScrollTrigger.create({
          trigger: headline,
          start: "top 80%",
          end: "top 50%",
          animation: animation,
          scrub: 2,
        });
      });
    });
    return () => mm.revert();
  });
  const features = [
    {
      number: "1",
      title: `${t("rambacaPage.baslik1")}`,
      img: `${AtikHava}`,
      content: `${t("rambacaPage.text1")}`,
    },
    {
      number: "2",
      title: `${t("rambacaPage.baslik2")}`,
      img: `${Elek}`,
      content: `${t("rambacaPage.text2")}`,
    },
    {
      number: "3",
      title: `${t("rambacaPage.baslik3")}`,
      img: `${Ekonomizer}`,
      content: `${t("rambacaPage.text3")}`,
    },
    {
      number: "4",
      title: `${t("rambacaPage.baslik4")}`,
      img: `${Yogusturma}`,
      content: `${t("rambacaPage.text4")}`,
    },
    {
      number: "5",
      title: `${t("rambacaPage.baslik5")}`,
      img: `${Ayristirma}`,
      content: `${t("rambacaPage.text5")}`,
    },
    {
      number: "6",
      title: `${t("rambacaPage.baslik6")}`,
      img: `${Damla}`,
      content: `${t("rambacaPage.text6")}`,
    },
    {
      number: "7",
      title: `${t("rambacaPage.baslik7")}`,
      img: `${RadyalFan}`,
      content: `${t("rambacaPage.text7")}`,
    },
  ];

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>Ram Filtresi | 2N Mekatronik®</title>
          <meta
            name="description"
            content="2N Mekatronik olarak Patentli Endüstriyel Baca Filtreleme Sistemleri hizmetini en kaliteli ve uygun fiyatlı olacak şekilde sunuyoruz. Bilgi Almak İçin Hemen Ara !0282 ..."
          ></meta>
          <meta
            property="og:description"
            content="2N Mekatronik olarak Patentli Endüstriyel Baca Filtreleme Sistemleri hizmetini en kaliteli ve uygun fiyatlı olacak şekilde sunuyoruz. Bilgi Almak İçin Hemen Ara !0282 ..."
          ></meta>
          <meta
            property="og:url"
            content="https://www.2nmekatronik.com.tr/ram-filtresi/"
          ></meta>
        </Helmet>
        <Breadcrumps
          baslik={t("yourFutureWeProtect")}
          page={t("pages.ramFilter")}
        />
        <div className="ram-filtre">
          <div className="container">
            <div
              className="rambaca mt4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 className="bslk txt-c">{t("ramExhaustFilter.title")}</h2>
              <div>
                <img
                  src={Banner}
                  className="mt3 banner-img "
                  alt="Ram Baca Filtresi"
                  width="100%"
                />
                <div className="ram-info mt4">
                  <div className="gallery">
                    <LightGallery
                      speed={500}
                      plugins={[lgThumbnail, lgShare, lgAutoplay, lgZoom]}
                    >
                      <a href={Hero1}>
                        <img
                          src={Hero1}
                          style={{ margin: "10px", cursor: "pointer" }}
                          alt="Baca Filtresi Çalışmalarımız"
                        />
                      </a>
                      <a href={Hero2}>
                        <img
                          src={Hero2}
                          style={{ margin: "10px", cursor: "pointer" }}
                          alt="Baca Filtresi Çalışmalarımız"
                        />
                      </a>
                      <a href={Hero3}>
                        <img
                          src={Hero3}
                          style={{ margin: "10px", cursor: "pointer" }}
                          alt="Baca Filtresi Çalışmalarımız"
                        />
                      </a>
                      <a href={Hero4}>
                        <img
                          src={Hero4}
                          style={{ margin: "10px", cursor: "pointer" }}
                          alt="Baca Filtresi Çalışmalarımız"
                        />
                      </a>
                    </LightGallery>
                  </div>
                  <div className="rambaca-txt mt4">
                    <p className="txt ">
                      <span style={{ fontWeight: 700 }}>{t("patent")},</span>{" "}
                      {t("urun1")} <br />
                      <br /> {t("urun2")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="spacer">
              <div className="baca-info">
                {features.map((feature) => (
                  <div className="info-texts">
                    <div className="info-text" key={feature.title}>
                      <h2 className="bslk2">
                        <span style={{ color: "#01ad4e" }}>
                          {feature.number}
                          <span style={{ color: "#0f75bc" }}>)</span>
                        </span>{" "}
                        {feature.title}
                      </h2>
                      <p className="txt mt2">{feature.content}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="info-images">
                <div className="info-image-bl">
                  <div className="info-image">
                    <div className="desktopPhoto red"></div>
                    <div className="desktopPhoto blue"></div>
                    <div className="desktopPhoto pink"></div>
                    <div className="desktopPhoto purple"></div>
                    <div className="desktopPhoto green"></div>
                    <div className="desktopPhoto damla"></div>
                    <div className="desktopPhoto gray"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="emisyon-deger">
              <h2>{t("ramExhaustFilter.emisyon.h")}</h2>

              <p className="txt">
                TOC değeri &lt;20 mg/Nm³ (Bacadan atılan her Nm³ hava içinde
                max. 20 mg ve altında olmalı)
              </p>
              <p className="txt">Koku değeri &lt;1000 KB (koku birimi)</p>
              <p className="txt">Yağ buharı &lt;15mg/m³</p>
              <hr />
              <h2>{t("ramExhaustFilter.emisyon.h2")}</h2>
              <p className="txt">
                Tekstil ve kimyasal sektörlerinde, proses çalışma koşullarından
                dolayı oluşan <br /> ve havaya yayılan uçucu organik
                bileşiklerin konsantrasyonudur.
              </p>
            </div>

            <div className="mobilRambaca">
              <div className="mobil-baca-info">
                {features.map((feature) => (
                  <div className="info-texts">
                    <div className="info-text" key={feature.title}>
                      <h2 className="bslk2"> {feature.title}</h2>
                      <img src={feature.img} width="50%" alt="" />
                      <p className="txt mt2">{feature.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default RamBacaP;
