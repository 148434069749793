import React,{useRef,useLayoutEffect} from 'react'
import './Info.css'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Clouds from '../Assets/baca-filtresi.png'
import Logoline from '../Assets/logo-line.png'
import { useTranslation } from 'react-i18next';


const Info = () => {
   const {t,i18n} = useTranslation()
    const main = useRef();
    useLayoutEffect(() => {
      gsap.registerPlugin(ScrollTrigger);
      let mm = gsap.matchMedia();
      mm.add('(min-width: 400px)', () => {
        gsap.utils.toArray('.sag').forEach((container) => {
          let image = container.querySelector('.logo-banner');
          gsap.to(image, {
            
            y:33,
            scrollTrigger: {
              
              start: 'top center',
              end: 'bottom center',
              trigger: container,
              id: 'pinned',
              scrub: 1,
            //   markers:true,
              invalidateOnRefresh: true,
            },
          });
        });
      });
      return () => mm.revert();
    });
   
    useLayoutEffect(() => {
      gsap.registerPlugin(ScrollTrigger);
      let mm = gsap.matchMedia();
      mm.add('(min-width: 400px)', () => {
        gsap.utils.toArray('.sag').forEach((container) => {
          let image = container.querySelector('.logo-line');
          gsap.to(image, {
            
            y:-30,
            scrollTrigger: {
              
              start: 'top center',
              end: 'bottom center',
              trigger: container,
              id: 'pinned',
              scrub: 1,
              
              invalidateOnRefresh: true,
            },
          });
        });
      });
      return () => mm.revert();
    });
  
  return (
    <div className='Info mt4'>
        <div className="container">
            <div className='g2c clgap4'>
                <div>
                  <h2 className='bslk pb5'>{t("whoWeAre")}</h2>
                  <p className='txt'>{t("paragraph1")}<br /><br /> 
                    {t("paragraph2")}<br /><br /> {t("paragraph3")}</p>
                      <div>
                        <a className='btn' style={{fontWeight:600,letterSpacing:'2px'}} href="ram-filtresi">
                          <div>
                          <p>{t("services")}</p>
                          <i class="fa-solid fa-arrow-right"></i>

                          </div>
                        </a>
                
                </div>
               
                </div>
                <div className='sag' >
                    <img className='logo-banner'  src={Clouds} alt="" />
                    <img className='logo-line' src={Logoline} alt="" />

                </div>

            </div>
           
        </div>
    </div>
  )
}

export default Info