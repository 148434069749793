import React,{useEffect} from 'react'
import Logo from '../Assets/2n-logo.png'
import {PreLoaderAnim, preLoaderAnim} from './animations'
import './PreLoader.css'

const PreLoader = () => {
    useEffect(()=>{
        preLoaderAnim();
    })
  return (
    <div className="preloader">
        <div className='texts-container'>
            <span><img src={Logo} alt="" width='150px' /></span>
            <span className='pre-text'>Geleceğinizi</span>
            <span className='pre-text'>Koruyoruz</span>
        </div>
    </div>
  )
}

export default PreLoader