import React from 'react'
import "./Breadcrumps.css"


const Breadcrumps = (props) => {  
  return (
    <div className='breadCrumps' >
        
    <div className='container' >
        <div>
            <div className='breadText'  >
                <div className='bread-baslik'>
                        <h2 className='bslk-breadcrmp'>{props.baslik} </h2>
                </div>
                
                <div className='bread-bslktxt'>
                    <h3>{props.page}</h3>
                    <div className='breadTexts'>
                        <a href="/">Anasayfa</a>
                        <div className='breadSplit'></div>
                        <p>{props.page}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="scroll-down-animation">
      
        <span class="mouse" >
            <span class="move"></span>
        </span>
        
    </div>
</div>


  )
}

export default Breadcrumps