import React,{useEffect} from 'react'
import './SocialMedia.css'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import { useTranslation } from 'react-i18next';

const SocialMedia = () => {
    const {t,i18n} = useTranslation()
    useGSAP(()=>{
        gsap.set(".social-banner",{ 
            })
          gsap.to(".social-banner", {
              scrollTrigger: {
                trigger: ".socialmedia",
                start: "top 88%",
                end:'top',
                scrub: true,
                once: true 
              },
              
              scale: 1,
              duration: 2,
              ease: "power2.out",
                });
    })


  return (
    <div className='socialmedia mt4'>
        <div className="container">
            <div className='social-banner'>
                
                    <div>
                        <h2 className='social-bslk'>
                            {t("followUs")}
                        </h2>
                    </div>
                    <div className='split'>
                        <div className="triangle"></div>
                        <div className="triangle"></div>
                        <div className="triangle"></div>
                    </div>
              
                <div className='social-icons'>
                    <a href="https://www.linkedin.com/company/2n-mekatronik/">
                        <div className='social-icon'>
                            <i class="fa-brands fa-linkedin-in"></i>
                            <h3>Linkedin</h3>
                        </div>
                    </a>
                    <a href="https://www.facebook.com/2nmekatronikcorlu/">
                        <div className='social-icon'>
                            <i class="fa-brands fa-facebook"></i>
                            <h3>Facebook</h3>
                        </div>
                    </a>
                    <a href="https://www.instagram.com/2nmekatronikcorlu/">
                        <div className='social-icon'>
                            <i class="fa-brands fa-instagram"></i>
                            <h3>İnstagram</h3>
                        </div>
                    </a>
                    <a href="https://www.youtube.com/channel/UCykbUZ1VQdLom5kIRVXOh1A">
                        <div className='social-icon'>
                            <i class="fa-brands fa-youtube"></i>
                            <h3>Youtube</h3>
                        </div>
                    </a>
     
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default SocialMedia