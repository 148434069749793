import React from 'react'
import './Haber.css'

const BirinciHaber = () => {
  return (
    <div className='haber'>
        <div className="container">
            <div className="haber-icerik">
            </div>
        </div>
    </div>
  )
}

export default BirinciHaber