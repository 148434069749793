import { GoogleMap,Marker, MarkerF} from '@react-google-maps/api';
import React ,{useState}from 'react';
import mapStyle from './style.json';
import MarkerYol from '../Assets/2n-logo.png'
import './ContactP.css'

const Map = (props)=>{
    const {isLoaded} = props;
    const containerStyle = {
        width: '100%',
        height: '100%',
       
      };
      
      const center = {
        lat: 40.9985658,
        lng: 28.8756890,
        };
      const corlu = {
        lat: 41.1428494,
        lng: 27.8445351,
      }
      const handleMarkerClick = () => {
        // Yönlendirmek istediğiniz URL'yi buraya ekleyin
        window.location.href = "https://maps.app.goo.gl/7o7R3Sxuts44HLV17";
    };
    const [markers, setMarkers] = useState([]);
        return isLoaded && (
            <>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={corlu}
              zoom={13}
              options={{
                disableDefaultUI: true,
                scrollwheel: false,
                styles:mapStyle,
              
                
              }}
              >
               

           
            <MarkerF
              position={corlu}
              title={'2N Mekatronik'}
             
              
              onClick={handleMarkerClick}
              
            />
          
               
            </GoogleMap>
            </>
            
            )
            
}
export default Map;