import React from 'react'
import './Filter.css'
import FiltreIcon from '../Assets/filtre-icon.png'
import UretimIcon from '../Assets/uretim-icon.png'
import KazancIcon from '../Assets/kazanc-icon.png'
import IklimIcon from '../Assets/iklim-icon.png'
import AtikIcon from '../Assets/atik-icon.png'

import { useTranslation } from 'react-i18next';
const Filter = () => {
   

    const {t,i18n} = useTranslation()
 
  return (
    <div id='sagliklicevre' className='filter'>
        <div className="container">
            <div className="propertys g5">
            <div className="card">
                <div className="card-shadow"></div>
                <img src={FiltreIcon} alt="" />
                <h2>{t('filtration')}</h2>
                <p>{t('filtrationDescription')}</p>
            </div>
            <div className="card">
                <div className="card-shadow"></div>
                <img src={UretimIcon} alt="" />
                <h2>{t('production')}</h2>
                <p>{t('productionDescription')}</p>
            </div>
            <div className="card">
                <div className="card-shadow"></div>
                <img src={KazancIcon} alt="" />
                <h2>{t('gain')}</h2>
                <p>{t('gainDescription')}</p>
            </div>
            <div className="card">
                <div className="card-shadow"></div>
                <img src={IklimIcon} alt="" />
                <h2>{t('climatisation')}</h2>
                <p>{t('climatisationDescription')}</p>
            </div>
            <div className="card">
                <div className="card-shadow"></div>
                <img src={AtikIcon} alt="" />
                <h2>{t('waste')}</h2>
                <p>{t('wasteDescription')}</p>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Filter