import React from 'react'
import './ReferenceP.css'
import Breadcrumps from '../Breadcrumps/Breadcrumps'
import { Helmet,HelmetProvider  } from 'react-helmet-async'


import Mertul from '../Assets/referans/mertul.png'
import Altinyildiz from '../Assets/referans/altinyildiz.png'
import senova from '../Assets/referans/senova.png'
import rayon from '../Assets/referans/rayon.png'
import yorukler from '../Assets/referans/yorukler.png'
import akinlar from '../Assets/referans/akinlar.png'
import assos from '../Assets/referans/assos.png'
import bakay from '../Assets/referans/bakay.png'
import elses from '../Assets/referans/else.png'
import ipektul from '../Assets/referans/ipektul.png'
import ismen from '../Assets/referans/ismen.png'
import mavis from '../Assets/referans/mavis.png'
import zeyland from '../Assets/referans/zeyland.png'
import ucelboya from '../Assets/referans/ucelboya.png'
import turbo from '../Assets/referans/turbo.png'
import supertekst from '../Assets/referans/super-tekstil.png'
import mozyurt from '../Assets/referans/ozyurt.png'
import miray from '../Assets/referans/miray.png'
import madiyo from '../Assets/referans/madiyo.png'
import koveka from '../Assets/referans/koveka-tekstil.png'
import istanbul from '../Assets/referans/istanbul-boyahanesi.png'
import halis from '../Assets/referans/halis.png'
import ecedoruk from '../Assets/referans/ecedoruk.png'
import dokuma from '../Assets/referans/dokuma-bayramlar.png'
import cevher from '../Assets/referans/cevher.png'
import cali from '../Assets/referans/cali-tekstil.png'
import birlik from '../Assets/referans/birlik.png'
import bayramlar from '../Assets/referans/bayramlar.png'
import ayboy from '../Assets/referans/ayboy tekstil.png'
import ariteks from '../Assets/referans/ariteks.png'
import { useTranslation } from 'react-i18next';


const ReferenceP = () => {
    const {t,i18n} = useTranslation()
  return (
    <HelmetProvider>
    <>
      <Helmet>
        <title>Referanslarımız | 2N Mekatronik®</title>
        <meta name="description" content="Kaliteli hizmetler sunarken çevreye zarar vermemek, geri dönüşüm sağlayan hizmetler sunmak, atık sıcak havanızın geri dönüşüm ile işletmenize enerji ..."></meta>
        <meta property="og:description" content="Kaliteli hizmetler sunarken çevreye zarar vermemek, geri dönüşüm sağlayan hizmetler sunmak, atık sıcak havanızın geri dönüşüm ile işletmenize enerji ..."></meta>
        <meta property="og:url" content="https://www.2nmekatronik.com.tr/referanslarimiz/"></meta>
      </Helmet>
    <Breadcrumps baslik='Geleceğinizi Koruyoruz !' page='Referanslarımız'/>
    <div className='Reference mt4'>
        <div className="container">
            <div>
                <h2 className='bslk-txt pb5'>{t("referenceHead.b1")} <br /> {t("referenceHead.b2")}</h2>
                <div className='g4 cards'>
                    <div>
                        <img src={Mertul} alt="" />
                    </div>
                    <div>
                        <img src={Altinyildiz} width={200} alt="" />
                    </div>
                    <div>
                        <img src={senova} width={200} alt="" />
                    </div>
                    <div>
                        <img src={rayon} width={200} alt="" />
                    </div>
                    <div>
                        <img src={yorukler} width={200} alt="" />
                    </div>
                    <div>
                        <img src={akinlar} width={200} alt="" />
                    </div>
                    <div>
                        <img src={assos} width={200} alt="" />
                    </div>
                    <div>
                        <img src={bakay} width={200} alt="" />
                    </div>
                    <div>
                        <img src={elses} width={200} alt="" />
                    </div>
                    <div>
                        <img src={ipektul} width={200} alt="" />
                    </div>
                    <div>
                        <img src={ismen} width={200} alt="" />
                    </div>
                    <div>
                        <img src={mavis} width={200} alt="" />
                    </div>
                    <div>
                        <img src={ucelboya} width={200} alt="" />
                    </div>
                    <div>
                        <img src={turbo} width={200} alt="" />
                    </div>
                    <div>
                        <img src={mozyurt} width={200} alt="" />
                    </div>
                    <div>
                        <img src={supertekst} width={200} alt="" />
                    </div>
                    <div>
                        <img src={zeyland} width={200} alt="" />
                    </div>
                    <div>
                        <img src={miray} width={200} alt="" />
                    </div>
                    <div>
                        <img src={madiyo} width={200} alt="" />
                    </div>
                    <div>
                        <img src={akinlar} width={200} alt="" />
                    </div>
                    <div>
                        <img src={ayboy} width={200} alt="" />
                    </div>
                    <div>
                        <img src={bayramlar} width={200} alt="" />
                    </div>
                    <div>
                        <img src={birlik} width={200} alt="" />
                    </div>
                    <div>
                        <img src={cali} width={200} alt="" />
                    </div>
                    <div>
                        <img src={koveka} width={200} alt="" />
                    </div>
                    <div>
                        <img src={istanbul} width={200} alt="" />
                    </div>
                    <div>
                        <img src={halis} width={200} alt="" />
                    </div>
                    <div>
                        <img src={ecedoruk} width={200} alt="" />
                    </div>
                    <div>
                        <img src={dokuma} width={200} alt="" />
                    </div>
                    <div>
                        <img src={cevher} width={200} alt="" />
                    </div>
                    <div>
                        <img src={ariteks} width={200} alt="" />
                    </div>
                </div>
               
               
            </div>
               
        </div>
    </div>
    </>
    </HelmetProvider>
  )
}

export default ReferenceP