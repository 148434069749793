import React from "react";
import "./Service.css";
import Baca from "../Assets/baca-filtre.png";
import Cimen from "../Assets/cimen.png";

import { useTranslation } from "react-i18next";

const RamBaca = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="rambaca mt4">
      <div className="container">
        <div className="text-center">
          <h4 className="bslk-txt">{t("cleanEnvironment")}</h4>
          <h2 className="bslk2">
            <span style={{ color: "rgba(15, 117, 188, 1)" }}>2N</span>{" "}
            {t("condensingEconomizerFilterSystem")}
          </h2>
        </div>
        <div className="g2 items-center mt3">
          <div>
            <ul className="text-left bslk-txt">
              <li>{t("listItems.item1")}</li>
              <li>{t("listItems.item2")}</li>
              <li>{t("listItems.item3")}</li>
              <li>{t("listItems.item4")}</li>
              <li>{t("listItems.item5")}</li>
            </ul>
          </div>
          <div className="baca-resmi">
            <img src={Baca} alt="" />
            <div className="artilar no1">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>{t("rambacaItems.item1")}</h2>
                <br />
                <p>{t("rambacaTexts.elyaf")}</p>
              </div>
            </div>
            <div className="artilar no2">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>{t("rambacaItems.item2")}</h2>
                <br />
                <p>{t("rambacaTexts.ekonomizer")}</p>
              </div>
            </div>
            <div className="artilar no3">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>{t("rambacaItems.item3")}</h2>
                <br />
                <p></p>
              </div>
            </div>
            <div className="artilar no4">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>{t("rambacaItems.item4")}</h2>
                <br />
                <p>{t("rambacaTexts.ayrıştırma")}</p>
              </div>
            </div>
            <div className="artilar no5">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>{t("rambacaItems.item5")}</h2>
                <br />
                <p>{t("rambacaTexts.radyal")}</p>
              </div>
            </div>
            <div className="artilar no6">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>{t("rambacaItems.item6")}</h2>
                <br />
                <p>{t("rambacaTexts.elektrostatik")}</p>
              </div>
            </div>
            <div className="artilar no7">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>{t("rambacaItems.item7")}</h2>
                <br />
                <p>{t("rambacaTexts.damla")}</p>
              </div>
            </div>
            <div className="artilar no8">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>
                  {t("rambacaItems.item8")} -{" "}
                  <span style={{ display: "inline", fontFamily: "sans-serif" }}>
                    2
                  </span>{" "}
                </h2>
                <br />
                <p>{t("rambacaTexts.yoğusma")}</p>
              </div>
            </div>
            <div className="artilar no9">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>
                  {t("rambacaItems.item8")} -{" "}
                  <span style={{ display: "inline", fontFamily: "sans-serif" }}>
                    1
                  </span>{" "}
                </h2>
                <br />
                <p>{t("rambacaTexts.yoğusma")}</p>
              </div>
            </div>

            <div className="artilar no10">
              <i class="fa-solid fa-plus "></i>
              <div className="aciklama">
                <h2>{t("rambacaItems.item9")}</h2>
                <br />
                <p>{t("rambacaTexts.filtreGiris")}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bird-container bird-container--one">
          <div class="bird bird--one"></div>
        </div>

        <div class="bird-container bird-container--two">
          <div class="bird bird--two"></div>
        </div>

        <div class="bird-container bird-container--three">
          <div class="bird bird--three"></div>
        </div>

        <div class="bird-container bird-container--four">
          <div class="bird bird--four"></div>
        </div>
      </div>
      <img className="cimen" src={Cimen} alt="" width={"100%"} />
    </div>
  );
};

export default RamBaca;
