import React from 'react'
import './Kazanimisi.css'
import Breadcrumps from '../Breadcrumps/Breadcrumps'
import { Helmet,HelmetProvider  } from 'react-helmet-async'
import { useTranslation } from 'react-i18next';

const Kazanimisi = () => {
  const {t,i18n} = useTranslation()
  return (
    <HelmetProvider>
    <>
    <Helmet>
    <title>Isı Geri Kazanımı | 2N Mekatronik®</title>
    <meta name="description" content="Sistem çalışması ile, ortamdan RAM makinası içine alınan (30-35) °C temiz hava, RAM ısıtıcıları ile kabin içinde (160 – 205) °C dereceye yükseltilip, RAM içerisinde sıcak hava fanları ile çevrimiçi kullanılıp ram egzoz fanı ile atmosfere boşaltma anında oluşan mecburi atık ısı kayıplarınız."></meta>
    <meta property="og:description" content="Sistem çalışması ile, ortamdan RAM makinası içine alınan (30-35) °C temiz hava, RAM ısıtıcıları ile kabin içinde (160 – 205) °C dereceye yükseltilip, RAM içerisinde sıcak hava fanları ile çevrimiçi kullanılıp ram egzoz fanı ile atmosfere boşaltma anında oluşan mecburi atık ısı kayıplarınız."></meta>
    <meta property="og:url" content="https://www.2nmekatronik.com.tr/isi-geri-kazanimi/"></meta>
    </Helmet>
    <Breadcrumps baslik={t("yourFutureWeProtect")} page={t("pages.heatRecovery")}/>
    <div className='isikazanim'>
        <div className="container">
            <div>
                <h2 className='bslk2'>{t("isiGeriKazanimi.title")}</h2>
                <p className='txt'>{t("isiGeriKazanimi.paragraphs.p1")}
                </p>
                <br />
                <p className='txt'>{t("isiGeriKazanimi.paragraphs.p2")}
                </p>
                <br />
                <p className="txt">{t("isiGeriKazanimi.paragraphs.p3")}</p>
                <br />
                <p className="txt" style={{color:'#01ad4e',fontWeight:700}}>{t("isiGeriKazanimi.paragraphs.p4")}</p>
            </div>
        </div>
    </div>
    </>
    </HelmetProvider>
    
  )
}

export default Kazanimisi