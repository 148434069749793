import React,{useState,useLayoutEffect} from 'react'
import Breadcrumps from '../Breadcrumps/Breadcrumps'
import './KurumsalP.css'
import İsiSistemleri from '../Assets/baca-filtresi.png'
import Video from '../Assets/2n-video.mp4'
import SocialMedia from '../SocialMedia/SocialMedia';
// gsap
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
// gsap

// sertifikalar
import Sertifika1 from '../Assets/certificate/sertifika-1.png'
import Sertifika2 from '../Assets/certificate/sertifika-2.png'
import Sertifika3 from '../Assets/certificate/sertifika-3.png'
import Sertifika4 from '../Assets/certificate/sertifika-4.png'
import Sertifika5 from '../Assets/certificate/sertifika-5.png'
import Sertifika6 from '../Assets/certificate/sertifika-6.png'
// sertifikalar


const KurumsalP = () => {
    // video 

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
      };
    
      const handleClose = (e) => {
        if (e.target.id === 'video-overlay' || e.target.className === 'video-overlay-close') {
          setIsOpen(false);
        }
      };
    
      const handleKeyUp = (e) => {
        if (e.keyCode === 27) {
          setIsOpen(false);
        }
      };
    //   boş
      useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let mm = gsap.matchMedia();
        mm.add('(min-width: 992px)', () => {
            ScrollTrigger.create( {
                start: 'top 10%',
                end: 'bottom bottom ',
                trigger: '.clouds',
                id: 'pinned',
                endTrigger: '.altservice',
                invalidateOnRefresh: true,
                // markers:true
              
            },
        );
        });
        return () => mm.revert();
      });
    //   boş
    //   acılan sayfa

      useGSAP(()=>{
        gsap.set(".vm-info",{ 
            })
          gsap.to(".vm-info", {
              scrollTrigger: {
                trigger: ".vision-mision",
                start: "top 88%",
                end:'top',
                scrub: 1,
                once:true,
              
              },
              borderRadius:6,
              scale: 1,
              duration: 2,
              ease: "power2.out",
                });

          

    })

  return (
    <>
        <Breadcrumps baslik='Geleceğinizi Koruyoruz !' page='Hakkımızda'/>
        <div className="kurumsal-p mt4">
            <div className="container">
                <div className='kurumsal-icerik g2 clgap4'>
                    <div>
                        <div onKeyUp={handleKeyUp}>
                            <div className="video-card">
                                <div className="shadow"></div>
                                <img src={İsiSistemleri} alt="İsi Sistemleri" width="100%" height="auto" />
                                <button id="play-video" className="video-play-button" onClick={handleOpen}>
                                <span></span>
                                </button>
                                <p className='tanitim'>Tanıtım Filmi <i class="fa-solid fa-video"></i></p>
                                <p>2N Mekatronik</p>
                            </div>

                            {isOpen && (
                                <div id="video-overlay" className="video-overlay open" onClick={handleClose}>
                                <a className="video-overlay-close" onClick={handleClose}>&times;</a>
                                <iframe
                                    src={Video}
                                    width="853"
                                    height="480"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen
                                ></iframe>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <h2 className='bslk2'>Farkımız Kalitemiz</h2>
                        <br />
                        <p className='txt'>Tekirdağ ili Çorlu ilçe sanayi bölgesinde faaliyet gösteren filtre ünitesi ve ısı geri kazanımı üzerine ve yine tekstil sektörüne yönelik verimlilik artırıcı özel projeler üzerine kendi sahasında üretim yapmaktadır. <br /><br />
                       
                         2 yıl süren proje çalışmalarımız akabinde projemizi 2019 yılında nihai ürün haline getirmiş olup, gerçek işletme şartlarında test 
                        çalışmalarına başladık. Bu çalışmalarımızın temelinde vazgeçmediğimiz 4 ana temel prensibimiz olan proje konu başlıklarımız;
                        </p>
                        <div>
                            <div className='kurumsal-cards mt4'>
                                <div className='kurumsal-card'>
                                    <span>Yangın riski olmayan</span>
                                </div>
                                <div className='kurumsal-card'>
                                    <span>RAM Kapasite üretim kaybı oluşturmayan</span>
                                </div>
                                <div className='kurumsal-card'>
                                    <span>İlgili RAM operatörünü meşgul etmeyen</span>
                                </div>
                                <div className='kurumsal-card'>
                                    <span>Yedek parça satınalma gerektirmeyen <span style={{fontWeight:700}}>sürdürülebilir proje ürünü olması</span></span>
                                </div> 
                            </div>
                        </div> 
                    </div>
                    
                </div>




            </div>
            
            {/* VİZYON MİSYON */}

            <div className='vision-mision mt4'>
                <div className="vm-info">
                    <div className="container">
                        <div className="wm-wrapper ">
                            <div className="item">
                                <div className="m-text">
                                    <h3 className='bslk2'><strong><em>Misyonumuz</em></strong></h3>
                                    <p className='txt'>Koşulsuz müşteri memnuniyetini sağlamak, teknolojik ve mesleki bilgi olarak sürekli gelişmek, yüksek kalitede üretim yapmak, çevreye ve topluma saygılı; çalışanlarının mutlu olduğu bir kurum olmak için mücadele vermek.</p>
                                </div>
                                
                                
                            </div>
                            <div className="item">
                                <div className="v-text">
                                    <h3  className='bslk2'><strong><em>Vizyonumuz</em></strong></h3>
                                    <p className='txt'>Ülkemizde ve dünyada sanayileşme insan yaşamında önemli rol oynamaktadır. Gerekli önlemler alınmadığı takdirde ise çevre kirliliği sorunu da sanayileşme ile beraber gündeme gelmektedir. Temiz Üretim Amaçlı Ram Baca Filtrasyonu ile sıfır duman atık, karbon salınımı azaltımı ile iklime destek, kötü kokuların bitirilmesini sağlamak</p>
                                </div>
                            </div>
                            <br /><br />
                            <div className="item">
                                <div className="m-text">
                                    <h3  className='bslk2'><strong><em>Kalite Politikamız</em></strong></h3>
                                    <p className='txt'>Kaliteli hizmetler sunarak müşteri ihtiyaçlarına çözümler üretmek, müşteri odaklı çalışmak, yüksek müşteri memnuniyeti sağlamak, güvenilir çözüm ortaklığı sunmak, uzman kadromuzla doğru çözümler sağlamak, çalışan güvenliğini korumak, müşteri karını artırmak, enerji tasarrufu sağlamak, endüstriyel ve diğer sektörlerde kaliteli hizmet sunmak, yeniliklere açık olup farklı ürünler üretmek ve iş sağlığı ve güvenliği kanunlarına uygun faaliyet göstermektir.</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="v-text">
                                    <h3  className='bslk2'><strong><em>Çevre Politikamız</em></strong></h3>
                                    <p className='txt'>Kaliteli hizmetler sunarken çevreye zarar vermemek, geri dönüşüm sağlayan hizmetler sunmak, atık sıcak havanızın geri dönüşüm ile işletmenize enerji tasarrufu sağlamak, baca gazı filtre sistemleri ile çevre kirliliğinin önüne geçmek, sanayi atıklarını filtreleyen sistemler kurmak, hizmet sağlarken çevreye ve topluma duyarlı olmak, çalışanlarımızı ve müşterilerimizi çevre temizliği hususunda bilinçlendirmek, müşteri taleplerini çevreye sorun oluşturmayacak şekilde gerçekleştirmek.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* VİZYON MİSYON */}

            {/* SERTİFİKALAR */}
            <div className='container mt4 certificate'>
                <div>
                    <h2 className='bslk2 txt-c'>Sertifikalarımız</h2>
                    <div className="g3 rwgap4 mt4 item-c">
                        <img src={Sertifika1} alt="2n mekatronik sertifika" width='300px' />
                        <img src={Sertifika2} alt="2n mekatronik sertifika" width='300px' />
                        <img src={Sertifika3} alt="2n mekatronik sertifika" width='300px' />
                        <img src={Sertifika4} alt="2n mekatronik sertifika" width='300px'/>
                        <img src={Sertifika5} alt="2n mekatronik sertifika" width='300px'/>
                        <img src={Sertifika6} alt="2n mekatronik sertifika" width='300px'/>
                    </div>
                </div>
            </div>
        </div>
        <SocialMedia/>
    </>
  )
}

export default KurumsalP